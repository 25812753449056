import { render, staticRenderFns } from "./returns.vue?vue&type=template&id=642952fa&"
import script from "./returns.vue?vue&type=script&lang=js&"
export * from "./returns.vue?vue&type=script&lang=js&"
import style0 from "./returns.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports