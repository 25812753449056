<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!-- 退貨單查詢 -->
        <div v-if="isView">
          <stats-card
            type="warning" 
            title="退貨單查詢"
            icon="now-ui-icons design_app">
          </stats-card>
          <stats-card
            type="warning"
            :title="'$' + formatLocalString(totalAmount)"
            subTitle="實際退貨金額"
            icon="now-ui-icons design_app">
          </stats-card>
        </div>
        <!-- 退貨作業 -->
        <div v-else>
          <stats-card
            type="warning"
            title="退貨作業"
            subTitle="進行退貨作業"
            icon="now-ui-icons design_app">
          </stats-card>
          <div class="card">
            <div class="card-header">
              <div class="card-subtotal container m-0">
                <div class="row">
                  <div class="col-12">
                    <div class="card-sec-title">實際退貨金額</div>
                    <h1 class="card-title">${{formatLocalString(computedSalePrice)}}</h1> 
                  </div>
                </div>
              </div>
            </div> 
            <hr>
            <div class="card-footer">
              <div class="row">
                <div class="col-lg-6 align-middle">共 {{computedProduct.number}} 項商品，合計 {{computedProduct.total}} 個。</div>
                <div class="col-lg-6 align-middle text-right" slot="footer">原訂單金額 ${{formatLocalString(totalAmount)}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 退貨單查詢 -->
        <div v-if="isView">
          <card class="pos-car">
            <div slot="header">
              <h5 class="card-title">原始訂單資料</h5>
            </div>
            <div class="table-responsive">
              <el-table :data="originOrder.saleDetails" empty-text="尚未有訂單紀錄" >
                <!-- <el-table-column v-for="col in cols" :key="col.property" :label="col.label" :property="col.property" :width="col.width" :align="col.align" :header-align="col['header-align']"></el-table-column> -->
                <el-table-column
                  label="商品編號"
                  property="SKU"
                ></el-table-column>
                <el-table-column
                  label="商品名稱"
                  property="name"
                ></el-table-column>
                <el-table-column
                  label="售價"
                  property="salePrice"
                  align="right"
                  header-align="right"
                >
                </el-table-column>
                <el-table-column
                  label="數量"
                  property="qty"
                  align="right"
                  header-align="right"
                >
                </el-table-column>
                <el-table-column
                  label="小計"
                  property="totalAmount"
                  align="right"
                  header-align="right"
                >
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">
                      <small>$</small>
                      {{ scope.row.totalAmount }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="專員"
                  property="salesName"
                  align="right"
                  header-align="right"
                ></el-table-column>
              </el-table>
            </div>
          </card>
        </div>
        <!-- 退貨作業 -->
        <div class="row" v-else>
          <div class="col-6">
            <card class="pos-car">
              <div slot="header">
                <h5 class="card-title">原始訂單資料</h5>
              </div>
              <div class="table-responsive">
                <el-table :data="originOrder.saleDetails" empty-text="尚未有訂單紀錄">
                  <el-table-column
                    label="商品編號"
                    property="SKU"
                    width="100"
                  ></el-table-column>
                  <el-table-column
                    label="商品名稱"
                    property="name"
                    min-width="100"
                  ></el-table-column>
                  <el-table-column
                    label="售價"
                    property="salePrice"
                    align="right"
                    header-align="right"
                  >
                  </el-table-column>
                  <el-table-column
                    label="數量"
                    property="qty"
                    align="right"
                    header-align="right"
                  >
                  </el-table-column>
                  <el-table-column
                    label="小計"
                    property="totalAmount"
                    align="right"
                    header-align="right"
                  >
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">
                        <small>$</small>
                        {{ scope.row.totalAmount }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="專員"
                    property="salesName"
                    align="right"
                    header-align="right"
                  ></el-table-column>
                  <el-table-column
                    align="right"
                    header-align="right"
                    min-width="50"
                  >
                    <template slot-scope="props">
                      <n-button @click.native="handleDelete(props.$index, props, 'remove')" class="remove" type="danger" size="sm" round icon>
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </card>
          </div>
          <div class="col-6">
            <card class="pos-car">
              <div slot="header">
                <h5 class="card-title">退貨項目</h5>
              </div>
              <div class="table-responsive">
                <el-table :data="returnsOrders" empty-text="尚未有退貨紀錄">
                  <el-table-column
                    label="商品編號"
                    property="SKU"
                    width="100"
                  ></el-table-column>
                  <el-table-column
                    label="商品名稱"
                    property="name"
                    min-width="100"
                  ></el-table-column>
                  <el-table-column
                    label="售價"
                    align="right"
                    header-align="right">
                    <template slot-scope="scope">
                      <input type="number" v-model.number="scope.row.salePrice" class="form-control text-center" min="1">
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="數量"
                    align="right"
                    header-align="right">
                    <template slot-scope="scope">
                      <input type="number" v-model.number="scope.row.qty" class="form-control text-center" min="1">
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="小計"
                    align="right"
                    header-align="right">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">
                        {{ formatLocalString(scope.row.totalAmount) }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="專員"
                    property="salesName"
                    align="right"
                    header-align="right"
                  ></el-table-column>
                  <el-table-column
                    align="right"
                    header-align="right"
                    min-width="50">
                    <template slot-scope="props">
                      <n-button @click.native="handleDelete(props.$index, props, 'recover')" class="success" type="success" size="sm" round icon>
                        <i class="now-ui-icons arrows-1_minimal-left"></i>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </card>
          </div>
        </div>
        <card v-if="!isView">
          <div class="text-right">
            <button type="button" class="btn btn-info btn-outline" @click.prevent="goToReturns" :disabled="computedDisabledPayment">退貨</button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { StatsCard, Card } from 'src/components'
import { Table, TableColumn, Select, Option } from 'element-ui'
import Swal from 'sweetalert2'
import API from 'src/api/index.js'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    StatsCard,
    Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      staff: [],
      salesName:  '',
      originOrder: [],
      returnsOrders: [],
      isCloseOrder: false,
      totalAmount: 0,
      isView: false,
      cols: [
        {label: '商品編號', property: 'SKU', width: '100'},
        {label: '商品名稱', property: 'name', width: '100'},
        {label: '售價', property: 'salePrice', align: 'right', 'header-align': 'right'},
        {label: '數量', property: 'qty'},
        {label: '小計', property: 'totalAmount', align: 'right', 'header-align': 'right'},
        {label: '專員', property: 'salesName', align: 'right', 'header-align': 'right', width: '50'},
        {label: '', property: 'remove', align: 'right', 'header-align': 'right', width: '50'},
        {label: '', property: 'recover', align: 'right', 'header-align': 'right', width: '50'}
      ]
    }
  },
  methods: {
    async init () {
      let staffResult = await API.getStaff()
      if (staffResult.message === 'success') {
        this.staff = staffResult.data
      }
      let closeResult = await API.checkCloseAccount()
      if (closeResult.message === 'success') {
        this.isCloseOrder = Object.keys(closeResult.data).length >= 1
      }
      let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
      if (localStorageData) {
        this.originOrder = localStorageData.order
        _.map(this.originOrder.saleDetails, async r => {
          let product = await API.getProduct(r.productUUID)
          r.productInfo = product.data[0]
          return r
        })
        this.totalAmount = this.originOrder.totalSaleAmount
      }
      // 若沒有開帳人員，則導入開帳頁面
      if (!this.isCloseOrder) {
        this.checkCloseAccount()
      }
      if (this.isView) {
        let removeIndex = _.findIndex(this.cols, {property: 'remove'})
        this.cols.splice(removeIndex, 1)
        let recoverIndex = _.findIndex(this.cols, {property: 'recover'})
        this.cols.splice(recoverIndex, 1)
      }
    },
    checkCloseAccount () {
      Swal.fire({
        title: '尚無開帳人員，確認後，前往開帳頁面',
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill',
        preConfirm: async () => {
          this.$router.push('/order')
        }
      })
    },
    goToReturns () {
      let {orderID, memberID, memberName, memberType, payment, invoiceType, invoiceNumber, taxID, taxType, taxRate, taxAmount } = this.originOrder
      let data = {
        returnOrderID: orderID,
        tradeDate: moment().format('X'),
        memberID,
        memberName,
        memberType,
        payment,
        invoiceType,
        invoiceDate: moment().format('X'),
        invoiceNumber,
        taxID,
        taxType,
        taxRate,
        taxAmount,
        totalSaleAmount: -this.computedSalePrice,
        saleDetails: this.returnsOrders.map(r => {
          let {SKU, barcode, category, name, productUUID, qty, quantityDiscout, quantityDiscoutPrice, rewardPrice, salePrice, staffID, staffName} = r
          return {SKU, barcode, category, name, productUUID, qty: -(qty), quantityDiscout, quantityDiscoutPrice, rewardPrice, salePrice, staffID, staffName}
        })
      }
      // 需擋下退款金額大於原始帳單金額，否則獎金會被多扣
      if (this.computedSalePrice > this.totalAmount) {
        Swal.fire({
          title: '退款金額錯誤',
          text: '退款金額大於原始帳單金額，請再確認一次',
          icon: 'error'
        })
        return
      }
      Swal.fire({
        title: '確認是否退款',
        showCancelButton: true,
        confirmButtonText: '確認退款',
        cancelButtonText: '取消',
        preConfirm: async () => {
          let reuslt = await API.returnSalesorder(JSON.stringify(data))
          if (reuslt.message === 'success') {
            this.$router.push('/order')
          }
        }
      })
    },
    handleSelect() {
      let item = _.filter(this.mappingStaff, {mappingName: this.salesName})[0]
      let {staffID, name, mappingName} = item
      let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
      if (localStorageData) {
        localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {returnSalesName: {mappingName, staffID, staffName: name}})))
      }
    },
    handleDelete(index, data, type) {
      // returnsOrders 退貨資料
      if (type === 'remove') {
        this.returnsOrders.push(data.row)
        this.originOrder.saleDetails.splice(index, 1)
      } else {
        this.originOrder.saleDetails.push(data.row)
        this.returnsOrders.splice(index, 1)
      }
    },
    formatLocalString (value) {
      return `${value.toLocaleString() }`
    }
  },
  computed: {
    computedSalePrice () {
      return _.chain(this.returnsOrders)
        .map(r => {
          // init totalAmount 金額
          r.totalAmount = 0
          if (r.quantityDiscout > 1 && r.qty >= r.quantityDiscout) {
            r.totalAmount = Math.floor(r.qty / r.quantityDiscout) * r.quantityDiscoutPrice + (r.qty % r.quantityDiscout) * r.salePrice
          } else {
            r.totalAmount += r.qty * r.salePrice
          }
          if (r.qty === r.originQty && r.salePrice === r.originSalePrice) {
            r.rewardPrice = -r.originRewardPrice
          } else {
            r.rewardPrice = -Math.round(r.originRewardPrice - (r.originRewardPrice / r.originQty) * r.qty)
          }
          return r
        })
        .map('totalAmount')
        .reduce((sum, totolAmount) => { return sum + totolAmount}, 0)
        .value()
    },
    computedProduct () {
      let total = _.chain(this.returnsOrders)
        .cloneDeep()
        .map('qty')
        .reduce((sum, qty) => { return sum + qty}, 0)
        .value()
      let number = _.get(this, 'returnsOrders', []).length
      return {number, total: Number(total)}
    },
    computedDisabledPayment () {
      // 沒有開帳人員 或 沒有退貨單數量就不可退款
      return !this.isCloseOrder || this.returnsOrders.length === 0
    },
    mappingStaff () {
      return _.chain(this.staff)
        .cloneDeep()
        .map(r => {
          r.mappingName = `(${r.staffID}) ${r.name}`
          return r
        })
        .value()
    }
  },
  async mounted () {
    if (this.$router.history.current.params.action === 'view') {
      this.isView = true
    }
    window.scroll(0, 0)
    this.init()
  }
}
</script>
<style>
  .input-text {
    border: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
    margin: 12px !important;
  }
</style>
